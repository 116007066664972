.work-page{
    min-height: 100vh;
    background-color: #0a0d24;
    /* border: solid 1px red; */
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    position: relative;
    overflow: hidden; 
    padding-bottom: 80px;
}

.sectionheading{
    position: absolute;
    color: #655e5e;
    right: 0;
    top: 35px;
    font-size: 175px;
    opacity: 0.2;
    font-family: 'Roboto Mono', monospace;
    margin: 0px;
    letter-spacing: -6px;
    font-weight: 500;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

@media screen and (min-width: 758px) and (max-width: 1190px) {
    .sectionheading {
        font-size: 120px;
    }
}

@media screen and (max-width: 757px) {
    .sectionheading {
        font-size: 70px;
        top: 50px;
    }
}

.vertical1 {
    position: absolute;
    border: solid 0.6px #97B0FF;
    /* height: 0; */
    left: 285px;
    /* animation: transheight1 2s forwards; */
}

[data-aos="vertical-anime1"] {
    height: 0;
    transition-duration: 2s;
    transition-property: height;
    &.aos-animate {
        height: 100%;
    }
}

/* @keyframes transheight1 {
    to {
        height: 100%;
    }
} */


.work-page-title{
    color: #807979;
    margin-left: 188px;
    font-weight: bold;
    margin-top: 34px;
    font-size: 29px;
}

@media screen and (max-width: 757px) {
    .work-page{
        padding-left: 43px;
        padding-right: 0px;
        padding-bottom: 25px;
    }

    .work-page-title{
        margin-left: 11px;
        font-size: 25px;
    }

    .vertical1 {
        left: 35px;
    }
}

@media screen and (min-width: 758px) and (max-width: 1190px) {
    .work-page {
        padding-left: 85px;
        padding-right: 70px;
        padding-bottom: 90px;
    }

    .work-page-title {
        margin-left: 105px;
    }

    .vertical1 {
        left: 168px;
    }
}

.project-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

@media screen and (max-width: 757px) {
    .project-container {
        margin-top: 80px;
    }
}

.parallax-effect {
    transform-style: preserve-3d;
    position: relative;
    box-shadow: inset 37px 0px 90px 9px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 37px 0px 90px 9px rgba(0,0,0,0.75);
    -webkit-box-shadow: inset 37px 0px 90px 9px rgba(0,0,0,0.75);
}

.project-arrow{
    transition: all 0.2s ease;
}

.parallax-effect:hover{
    .project-arrow{
        transform: translateX(9px);
    }

    box-shadow: inset 37px 0px 90px 9px rgba(0,0,0,0.75), 0px 0px 56px 11px rgba(0,0,0,0.75);
}

.inner-element{
    transform: translateZ(40px);
    position: absolute;
    height: 160px;
    left: -60;
    bottom: 0;
    width: 150px;
    margin-left: -50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 24px;
}

.inner-element span {
    color: #ffffff;
    font-size: 23px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 1.2;
    font-weight: 900;
}

@media screen and (max-width: 757px) {
    .inner-element span {
        font-size: 23px;
    }

    .inner-element {
        padding-bottom: 0px;
        padding-top: 20px;
        height: 167px;
        width: 140px;
        justify-content: flex-start;
        margin-left: -50px;
    }

    .parallax-effect {
        margin-left: 40px;
    }
}


@media screen and (min-width: 758px) and (max-width: 1190px) {
    .inner-element span {
        font-size: 23px;
    }
}


.project-1{
    background-image: url('../../assets/658ed6b25e2c05b95afdff7f--serene-duckanoo-87f513.netlify.app_\ \(1\).png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 235px;
    width: 420px;
}

.project-2{
    background-image: url('../../assets/youtube-clone-nithish-mnl.vercel.app_\ \(1\).png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 235px;
    width: 420px;
}

.project-3 {
    background-image: url('../../assets/elegant-pithivier-566918.netlify.app_\(iPhone\ 12\ Pro\).png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 410px;
    width: 245px;
}

.project-4 {
    background-image: url('../../assets/nithishjobbyapp.ccbp.tech_.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 235px;
    width: 420px;
}

.project-5{
    background-image: url('../../assets/nithishemoji.ccbp.tech_\(iPhone\ 12\ Pro\).png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 410px;
    width: 245px;
}

.project-6{
    background-image: url('../../assets/nithishecom.ccbp.tech_.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 235px;
    width: 420px;
}

@media screen and (max-width: 757px) {
    .project-1, .project-2, .project-4, .project-6 {
        width: 222px;
        height: 139px;
    }

    .project-3, .project-5 {
        height: 372px;
        width: 222px;
    }
}

@media screen and (min-width: 758px) and (max-width: 1190px) {
    .project-1, .project-2, .project-4, .project-6 {
        width: 350px;
        height: 200px;
    }

    .project-3, .project-5 {
        height: 372px;
        width: 222px;
    }
}

.project-anchor {
    text-decoration: none !important;
    display: inline-block;
}

.individual-container-1 {
    width: 70%;
}

[data-aos="anime-1"] {
    transform: translateX(-100px);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translateX(0px);
        opacity: 1;
    }
}

.individual-container-2 {
    width: 68%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: translateY(-20px);
}

[data-aos="anime-2"] {
    transform: translate(0px, 100px);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translate(0px, -20px);
        opacity: 1;
    }
}


.individual-container-3{
    width: 32%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: translateY(-100px);
}

[data-aos="anime-3"] {
    transform: translate(100px, -100px);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translate(0px, -100px);
        opacity: 1;
    }
}

.individual-container-4 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-35px);
}

[data-aos="anime-4"] {
    transform: translate(-100px, -35px);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translate(0px, -35px);
        opacity: 1;
    }
}

.individual-container-5 {
    display: flex;
    width: 77%;
    justify-content: flex-end;
    transform: translateY(-10px);
}

[data-aos="anime-5"] {
    transform: translate(100px, -10px);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translate(0px, -10px);
        opacity: 1;
    }
}

.individual-container-6 {
    width: 95%;
    display: flex;
    justify-content: center;
    transform: translateY(-110px);
}

[data-aos="anime-6"] {
    transform: translate(0px, 100px);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translate(0px, -20px);
        opacity: 1;
    }
}

@media screen and (min-width: 758px) and (max-width: 1190px) {
    [data-aos="anime-1"] {
        transform: translateX(-100px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    [data-aos="anime-2"] {
        transform: translate(100px, -20px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translate(0px, -20px);
            opacity: 1;
        }
    }

    [data-aos="anime-3"] {
        transform: translate(-100px, -50px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translate(0px, -50px);
            opacity: 1;
        }
    }
    
    [data-aos="anime-4"] {
        transform: translate(100px, 40px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translate(0px, 40px);
            opacity: 1;
        }
    }

    [data-aos="anime-5"] {
        transform: translate(-100px, 60px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translate(0px, 60px);
            opacity: 1;
        }
    }

    [data-aos="anime-6"] {
        transform: translate(100px, 10px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translate(0px, 10px);
            opacity: 1;
        }
    }
    
}

@media screen and (max-width: 757px) {
    [data-aos="anime-1"] {
        transform: translateX(-100px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    [data-aos="anime-2"] {
        transform: translateX(100px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    [data-aos="anime-3"] {
        transform: translateX(-100px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translateX(0px);
            opacity: 1;
        }
    }
    
    [data-aos="anime-4"] {
        transform: translateX(100px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    [data-aos="anime-5"] {
        transform: translateX(-100px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    [data-aos="anime-6"] {
        transform: translateX(100px);
        opacity: 0;
        transition-property: transform, opacity;
        &.aos-animate {
            transform: translateX(0px);
            opacity: 1;
        }
    }
    
}

@media screen and (max-width: 757px) {
    .individual-container-1, .individual-container-2, .individual-container-3, .individual-container-4,
    .individual-container-5, .individual-container-6 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 93px;
    }

    .individual-container-6 {
        margin-bottom: 40px;
    }
}

@media screen and (min-width: 758px) and (max-width: 1190px) {
    .individual-container-2 {
        width: 100%;
    }

    .individual-container-4 {
        width: 60%;
        justify-content: flex-end;
        /* transform: translateY(40px); */
    }

    .individual-container-5 {
        justify-content: flex-start;
        /* transform: translateY(60px); */
    }

    .individual-container-6 {
        width: 100%;
        /* transform: translateY(10px); */
        justify-content: flex-end;
    }
}

