*{
    box-sizing: border-box !important;
    font-family: 'Roboto', sans-serif;
}

body{
    margin: 0px;
}

html {
    scroll-behavior: smooth;
}

@media screen and (max-width: 767px) {
    body {
        cursor: none !important;
    }
}

::selection {
    background: #7f95e6;
    color: #ffffff;
}

button:focus {
    outline: none;
}