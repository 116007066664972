.start-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;
    /* padding-bottom: 100px; */
    padding-top: 260px;
    padding-bottom:47px;
    position: relative;
    overflow: hidden;
}

.vertical {
    position: absolute;
    border: solid 0.6px #97B0FF;
    border-radius: 8px;
    height: 0;
    left: 285px;
    animation: transheight 2s forwards;
    margin-top: 8px;
    animation-delay: 0.2s;
}

@keyframes transheight {
    to {
        height: 100%;
    }
}

.bullet {
    position: absolute;
    padding: 2.5px;
    border: solid 2px #88a2ff;
    background-color: #06081a;
    border-radius: 50%;
    z-index: 2;
    left: 280.9px;
    margin-top: 8px;
}

.name-container{
    width: 100%;
    padding-left: 308px;
}


.myname-para{
    color: #f1f1f1;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 17px;
    margin-bottom: 0px;
}

/* start */

.block-reveal-span {
    color: #f1f1f1;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 17px;
    padding-left: 1.37px;
}

.block-reveal-span {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.block-reveal-span::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    animation: a-ltr-after 0.8s cubic-bezier(0.65, 0.05, 0.36, 1)
    forwards;
    transform: translateX(-101%);
}

.block-reveal-span::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #06081a;
    animation: a-ltr-before 0.8s cubic-bezier(0.65, 0.05, 0.36, 1)
    forwards;
    transform: translateX(0);
}

.block-reveal-span::before,
.block-reveal-span::after {
    animation-delay: 0.93s;
}

/* end */

.name-para{
    background: linear-gradient(to right, #bdd4ff, #88a2ff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-top: 3px;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    font-size: 46px;
    margin-bottom: -5px;
}

.third-para{
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 42px;
    margin-top: 0px;
    font-weight: 700;
    margin-bottom: 13px;
}

@media screen and (max-width: 757px) {
    .third-para {
        height: 90px;
        margin-bottom: 8px;
    }
}

/* start */

.third-para-span{
    position: relative;
    overflow: hidden;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 42px;
    margin-top: 0px;
    font-weight: 900;
}

.third-para-span::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    animation: a-ltr-after 0.8s cubic-bezier(0.65, 0.05, 0.36, 1)
    forwards;
    transform: translateX(-101%);
}

.third-para-span::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #06081a;
    animation: a-ltr-before 0.8s cubic-bezier(0.65, 0.05, 0.36, 1)
    forwards;
    transform: translateX(0);
}

.third-para-span::before,
.third-para-span::after {
    animation-delay: 2.2s;
}



/* end*/

.serif-span{
    color: #ffffff;
    font-family: Palatino, Georgia, 'Times New Roman', Times, serif;
    font-weight: 400;
    font-style: italic;
}

.develop-span{
    color: #ffffff;
    font-family: Roboto Mono, monospace !important;
}

.line-break{
    display: none;
}

.extra-small-device-line-break {
    display: none;
}

/* start */

.block-reveal-span-heading{
    background: linear-gradient(to right, #bdd4ff, #88a2ff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: bolder;
    font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 757px) {
    .block-reveal-span-heading {
        font-size: 31px;
    }
}

.block-reveal-span-heading {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.block-reveal-span-heading::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #88a2ff;
    animation: a-ltr-after 0.8s cubic-bezier(0.65, 0.05, 0.36, 1)
    forwards;
    transform: translateX(-101%);
}

.block-reveal-span-heading::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #06081a;
    animation: a-ltr-before 1.3s cubic-bezier(0.65, 0.05, 0.36, 1)
    forwards;
    transform: translateX(0);
}

.block-reveal-span-heading::before,
.block-reveal-span-heading::after {
    animation-delay: 1.6s;
}



/* end */

.resume-anchor {
    color: #f1f1f1;
    font-family: 'Roboto', sans-serif;
    text-decoration: none !important;
    display: inline-block;
}

.arrow{
    /* margin-left: 4px; */
    transform: translate(0px, 2px);
    opacity: 0;
    animation: blockarrow forwards;
    animation-delay: 3s;
}

@keyframes blockarrow {
    to {
        opacity: 1;
    }
}

/* start */

.resume-span{
    color: #f1f1f1;
    text-decoration: none !important;
    transition: all 0.24s ease-out;
    display: inline-block;
    position: relative;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

.resume-span:hover{
    color: #bdd4ff !important;

    .arrow {
        transform: translate(3.2px, -1.5px);
    }
}

.resume-span::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    animation: a-ltr-after 0.8s cubic-bezier(0.65, 0.05, 0.36, 1)
    forwards;
    transform: translateX(-101%);
}

.resume-span::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #06081a;
    animation: a-ltr-before 0.8s cubic-bezier(0.65, 0.05, 0.36, 1)
    forwards;
    transform: translateX(0);
}

.resume-span::before,
.resume-span::after {
    animation-delay: 2.73s;
}



/* end */


@keyframes a-ltr-after {
    0% {
        transform: translateX(-100%)
    }

    100% {
        transform: translateX(101%)
    }
}

@keyframes a-ltr-before {
    0% {
        transform: translateX(0)
    }

    100% {
        transform: translateX(200%)
    }
}

@media screen and (max-width: 359px) {
    .extra-small-device-line-break {
        display: block;
    }
}


@media screen and (max-width: 757px) {
    .start-page{
        padding-top: 285px;
        padding-bottom: 90px;
    }

    .name-container{
        padding-left: 54px;
    }

    .name-para{
        font-size: 31px;
    }

    .third-para, .third-para-span{
        font-size: 29px;
        /* margin-right: 55px; */
    }

    .line-break{
        display: block;
    }

    .vertical {
        left: 35px;
    }

    .bullet {
        left: 30.8px;
    }
}

@media screen and (min-width: 758px) and (max-width: 1190px) {
    .name-container{
        padding-left: 190px;
    }

    .vertical {
         left: 168px;
    }

    .bullet {
        left: 163.8px;
    }
}

.mouse-button{
    text-decoration: none;
    align-self: center;
    transition: all 0.3s;
    transform: translateY(-40px);
    opacity: 0;
    animation: mouse 1.4s forwards;
    animation-delay: 3s;
}


@keyframes mouse {
    to {
        transform: translateY(8px);
        opacity: 1;
    }
}

.mouse-button:hover{
    transform: translateY(8px) scale(1.17) !important;
}

@media screen and (max-width: 757px) {
    .mouse-button {
        animation: mouse2 1.4s forwards;
    }

    .mouse-button:hover{
        transform: translateY(35px) scale(1.17) !important;
    }
}

@keyframes mouse2 {
    to {
        transform: translateY(35px);
        opacity: 1;
    }
}






.anime-span{
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    overflow: hidden;
    will-change: transform;
    white-space: nowrap;
}
