.background-container{
    background-color: #06081a;
    min-height: 100vh;
}

.loader-container{
    background-color: #06081a;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #06081a; 
}
::-webkit-scrollbar-thumb {
    background: #4c4747;
    border-radius: 10px;
}

.ml15 {
    text-align: center;
    opacity: 0;
    animation: final 1.3s forwards linear;
    animation-delay: 400ms;
}


@keyframes final {
    to {
        opacity: 1;
    }
}


.ml15 .word {
    display: inline-block;
    width: 230px;
}