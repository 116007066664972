.nav-container{
    background: #06081a;
    width: 100%;
    box-shadow: 0 0 15px 13px #06081a;
    opacity: 0.9;
    position: fixed;
    top: 0;
    z-index: 2;
    transition: top 0.55s ease;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    padding: 10px 28px;
    padding-bottom: 0px;
}

.nav-header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.logo{
    width: 172px;
    transition: all 0.20s ease-out;
    opacity: 0;
    animation: navLogo 1.3s forwards;
}

@keyframes navLogo {
    to {
        opacity: 1;
    }
}

@media screen and (max-width: 750px) {
    .logo{
        width: 157px;
    }

    .nav-container{
        padding: 9.5px 16px;
        margin-bottom: 0px;
        flex-direction: column;
        justify-content: flex-start;
    }
}

.logo:hover{
    transform: scale(1.10);
}

.nav-link-container{
    display: block;
}

@media screen and (max-width: 757px) {
    .nav-link-container{
        display: none;
    }
}

.nav-link-element{
    color: #9a9191;
    text-decoration: none;
    margin-left: 21px;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    font-size: 21px;
    transition: all 0.37s;
    display: inline-block;
    opacity: 0;
    transform: translateY(-20px);
    animation: navSpan 0.7s forwards;
}

@media screen and (max-width: 757px) {
    .nav-link-element {
        display: inline;
        opacity: 1;
        transform: none;
        animation: none;
    }
}

.nav-link-delay-1{
    animation-delay: 0s;
}

.nav-link-delay-2{
    animation-delay: 0.17s;
}

.nav-link-delay-3{
    animation-delay: 0.34s;
}

.nav-link-delay-4{
    animation-delay: 0.47s;
}

@keyframes navSpan {
    to {
       transform: translateY(0px);
       opacity: 1;
    }
}



@media screen and (max-width: 757px) {
    .nav-link-element{
        margin-bottom: 24px;
        margin-left: 0px;
        margin-right: 0px;
    }
}

.active{
    color: #ffffff;
}

.nav-link-element:hover{
    color: #ffffff;
}

.nav-span{
    color: #4c4747;
}

.menu-button{
    display: none;
    cursor: pointer;
    background-color: #141424;
    border: solid 1px #363131;
    border-radius: 7px;
    padding: 1px 10px;
}

@media screen and (max-width: 757px) {
    .menu-button{
        display: block;
    }
}

.nav-link-container-mobile{
    display: flex;
    width: 100%;
    flex-grow: 1;
    background:transparent;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 0;
    overflow: hidden;
    transition: height 0.45s ease;
}

@media screen and (min-width: 757px) {
    .nav-link-container-mobile{
        display: none;
    }
}

.show-nav{
    height: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
}